import React from "react";
import {NavLink} from 'react-router-dom';

function AGB() {
    return(
        <div className="w-full">
            <div className="flex pl-6 items-center h-16 bg-indigo-700">
                <NavLink to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 stroke-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </NavLink>
            </div>
            <div className="flex h-full">
                <div className="flex-1 bg-gray-200 flex justify-center pt-4 px-5">
                    <p class="prose lg:prose-lg max-w-5xl pb-8">
                        <h1 class="underline decoration-indigo-500">Allgemeine Geschäftsbedingungen</h1>
                        <p>erstellt mit Deutschen Anwaltshotline AG<br></br>Vertragspartner(Lizenzfreie und bearbeitete Listung)</p>
                        <p>Auf Grundlage dieser Allgemeinen Geschäftsbedingungen (AGB) kommt zwischen dem Kunden und Projekte aus Holz / Weinfassfeuer vertreten durch Torsten Randhahn, Adresse: Carl-Schmöle-Str 51c 58706 Menden, Tel: 023739813133, E-Mail-Adresse: info@weinfassfeuer.com, der Vertrag zustande.</p>
                    
                        <h1 class="pt-5 text-3xl">Vertragsgegenstand</h1>
                        <p>Durch diesen Vertrag wird der Verkauf von neuen Waren aus dem Bereich/den Bereichen Handarbeit Holzprodukte Kunsthandwerk über das Portal des Anbieters geregelt. Wegen der Details des jeweiligen Angebotes wird auf die Produktbeschreibung der Angebotsseite verwiesen. Alle gezeigten Produkte sind Sonder/Massanfertigungen sie werden ausgenommen und werden einzeln geregelt. Nicht alle Abbildungen im System stellen das angebotene Produkt zwingend dar. Sie dienen zur Visualisierung der Beschreibung oder zur Orientierung. Dies gilt hauptsächlich für Abbildungen mit mehreren Produkten.</p>

                        <h1 class="pt-5 text-3xl">Vertragsschluss</h1>
                        <p>Der Vertrag kommt im elektronischen Geschäftsverkehr über das Shop-System  oder über andere Fernkommunikationsmittel wie Telefon und E-Mail zustande. Der Bestellvorgang zum Vertragsschluss umfasst im Shop-System(Ausschliesslich durch E-mail) folgende Schritte:</p>
                        <ul>
                            <li>• Auswahl des Angebots in der gewünschten Spezifikation (Größe, Farbe, Anzahl) (Dies gilt nicht bei Sonderanfertigungen. Hier muss das jeweilige Projekt im Einzelnen per Email schriftlich in Art und Ausführung beschrieben sein inkl. des Endpreises nach Fertigstellung. Jede Änderung wie Grösse und Form entspricht nicht mehr dem Standartartikel auf den Seiten. Somit verändern sich die Preise und müssen vor Vertragsabschluss erfragt werden.</li>
                            <li>• Bestellung ausschließlich durch Email oder Digitalmessenger. Hier zu ist der Name und die Postanschrift zur Auftragsannahme erforderlich . </li>
                            <li>• Durch AN Bestätigungsmail/Bestätigungsmessege durch Soziales-Netzwerk, dass Bestellung eingegangen ist.</li>
                            <li>• Der Vertragsabschluss hat begonnen.</li>
                        </ul>

                        <h1 class="pt-5 text-3xl">Vertragsschluss</h1>
                        <p>Im Falle eines Kaufvertrags: an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.<br></br>Im Falle einer Vertrags über mehrere Waren, die der Verbraucher im Rahmen einer einheitlichen Bestellung bestellt hat und die getrennt geliefert werden, an dem Sie oder ein von Ihnen benannter Dritter, der nicht Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat. <br></br>Im Falle eines Vertrags über die Lieferung einer Ware in mehreren Teilsendungen oder Stücken, an dem Sie oder ein von Ihnen benannter Dritter, der nicht Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw. hat.<br></br>Im Falle eines Vertrages zur regelmäßigen Lieferung von Waren über einen festgelegten Zeitraum hinweg, an dem Sie oder ein von Ihnen benannter Dritter, der nicht Beförderer ist, die erste Ware in Besitz genommen haben bzw. hat.</p>
                    
                        <h1 class="pt-5 text-3xl">Haftungsausschluss</h1>
                        <ul>
                            <li>Schadensersatzansprüche des Kunden sind ausgeschlossen, soweit sich aus den nachfolgenden Gründen nicht etwas anderes ergibt. Dies gilt auch für den Vertreter und Erfüllungsgehilfen des Anbieters, falls der Kunde gegen diese Ansprüche auf Schadensersatz erhebt. Ausgenommen sind Schadensersatzansprüche des Kunden wegen Verletzung des Lebens, des Körpers, der Gesundheit oder wesentlicher Vertragspflichten, welche zur Erreichung des Vertragszieles notwendigerweise erfüllt werden müssen. Ebenso gilt dies nicht für Schadensersatzansprüche nach grob fahrlässiger oder vorsätzlicher Pflichtverletzung des Anbieters oder seines gesetzlichen Vertreters oder Erfüllungsgehilfen.</li>
                            <li>Rechtschreibfehler in Emails des AG, die Namen und Daten beschreiben und auf bestellte Artikel geschrieben oder gedruckt werden sollen, sind Eigenverschulden des AG und werden vom AN auf die Artikel übertragen.. Eine Änderung auf dem gefertigten Artikel ist ein Mehraufwand und muss schriftlich vom AG angezeigt werden . Ein Rücktritt des Kaufvertrages nach entstandenem Schaden aus dem vorgenannten Grund besteht nicht.</li>
                            <li>Bei unsachgemäßer Installation entgegen der Hinweissanleitung und/oder baulicher Veränderung erlischt die gesetzliche und vertragliche Gewährleistung</li>
                            <li>Die bereitgestellten Informationen auf dieser Webseite wurden sorgfältig geprüft und werden regelmässig gewartet. Es kann jedoch keine Haftung oder Garantie dafür übernommen werden, das alle Angaben zu jeder Zeit vollständig, korrekt und in letzter Aktualität angezeigt werden. Der Anbieter Torsten Randhahn,Carl-Schmöle-Str.51c,58706 Menden,Telefonnr.: 023739813133, Email:TorstenRandhahn@gmail.com behält sich das Recht vor, die bereitgestellten Informationen jederzeit zu ändern oder zu ergänzen. Alle Bilder sind von Torsten Randhahn erstellt worden und unterliegen keinem Copyright. Ich erstelle keine Vereinslogos und oder vertreibe diese. Farben sind frei erfunden und haben keinen Bezug zu Vereine.</li>
                            <li>Es handelt sich bei allen Artikeln um kein Kinderspielzeug. Alle Artikel entsprechen nicht der Spielzeugnorm.</li>
                            
                            <h1 class="pt-5 text-3xl">Vorbehalte</h1>
                            <li>Der Anbieter behält sich vor, eine in Qualität und Preis gleichwertige Leistung zu erbringen. Die im Shop dargestellte Leistung ist beispielhaft und nicht die individuelle, vertragsgegenständliche Leistung. Der Anbieter behält sich vor, im Falle der Nichtverfügbarkeit der versprochenen Leistung diese nicht zu erbringen.</li>
                            <li>Bis zur vollständigen Bezahlung bleibt die gelieferte Ware im Eigentum des Anbieters.</li>
                            
                            <h1 class="pt-5 text-3xl">Zahlungsbedingungen</h1>    
                            <li>Der Kunde hat ausschließlich folgende Möglichkeiten zur Zahlung: Vorabüberweisung, Zahlungsdienstleister (PayPal), Barzahlung bei Abholung, Debitkartenzahlung bei Abholung der Ware. Weitere Zahlungsarten werden nicht angeboten und werden zurückgewiesen. Der Rechnungsbetrag ist nach Zugang der Rechnung, die alle Angaben für die Überweisung enthält und mit E-Mail verschickt wird, auf das dort angegebene Konto vorab zu überweisen. Bei Verwendung eines Treuhandservice/ Zahlungsdienstleisters ermöglicht es dieser dem Anbieter und Kunden, die Zahlung untereinander abzuwickeln. Dabei leitet der Treuhandservice/ Zahlungsdienstleister die Zahlung des Kunden an den Anbieter weiter. Weitere Informationen erhalten Sie auf der Internetseite des jeweiligen Treuhandservices/ Zahlungsdienstleisters. Der Kunde ist verpflichtet innerhalb von 7 Tagen nach Erhalt der Rechnung den ausgewiesenen Betrag auf das auf der Rechnung angegebene Konto einzuzahlen oder zu überweisen. Die Zahlung ist ab Rechnungsdatum ohne Abzug fällig. Nach Ablauf der Zahlungsfrist, die somit kalendermäßig bestimmt ist, kommt der Kunde auch ohne Mahnung in Verzug. Bei Versand ist die Ware inkl. Versandkosten im voraus zu begleichen.</li>
                            <li>Sonderanfertigungen, dazu gehören Grabmale und Stelen die mit einem Namen und Daten versehen werden, müssen nach Vertragsabschluss und vor Ausführung der Arbeiten zu 100% der Auftragssumme vorab bezahlt werden.</li>
                        
                            <h1 class="pt-5 text-3xl">Lieferbedingungen, Lieferkosten, Rücksendung, Preise</h1>
                            <li>Alle Preise sind Endpreise und enthalten gem. § 19 Abs. 1 UStG keine Umsatzsteuer (Mehrwertsteuer). Neben den Endpreisen fallen je nach Versandart weitere Kosten an. Besteht ein Widerrufsrecht und wird von diesem Gebraucht gemacht, trägt der Kunde die Kosten der Rücksendung. Ebenso trägt der AG die Rücksendekosten bei Versandschäden. Bei Sonderanfertigungen benennt der AN schriftlich per Mail/Messenger den Preis</li>
                            <li>Die Ware wird umgehend nach bestätigtem Zahlungseingang versandt. Der Versand erfolgt durchschnittlich spätestens nach 10 Tagen. Die Lieferzeit von bestellten Produkten wird schriftlich in der Auftragsbestätigung aufgeführt und vom AN bestimmt. Der AN versendet die Bestellung aus eigenem Lager, sobald die gesamte Bestellung dort vorrätig ist. Der Kunde wird über Verzögerungen umgehend informiert. Hat der Anbieter ein dauerhaftes Lieferhindernis, insbesondere höhere Gewalt oder Nichtbelieferung durch eigenen Lieferanten, obwohl rechtzeitig ein entsprechendes Deckungsgeschäft getätigt wurde, nicht zu vertreten, so hat der Anbieter das Recht, insoweit von einem Vertrag mit dem Kunden zurückzutreten. Der Kunde wird darüber unverzüglich informiert und empfangene Leistungen, insbesondere Zahlungen, zurückerstattet. Die Lieferung wird durch Hermesversand und/oder GLS durchgeführt. Jede Bestellung/Artikel wird einzeln Versand. Der Versand erfolgt durch Hermes und GLS ausschließlich nach Deutschland(Festland) Die Versandkosten für einen Artikel durch Hermesversand betragen 12,00 Euro. Die Versandkosten für Gartenstelen erfolgt durch GLS und betragen 20,00 Euro. Bei Artikeln über 1,30 Metern länge werden 35,00 Euro Sperrgutkosten berechnet. Eine Gewährleistung/Einhaltung für einen genannten Liefertermin vom Zusteller lehnen wir ab und es gelten die AGB der Fa. Hermesversand und GLS.</li>
                            
                            <h1 class="pt-5 text-3xl">Überlassung des Urheberrechts von Privatfotos und Veröffentlichung von daraus erstellten Produkten</h1>
                            <li>Bei Silhouetten überlässt der Kunde ausschließlich Privatfotos zur Erstellung der Wanddekoration dem Auftragnehmer. Der Kunde tritt für den Zeitraum der Produktion das Recht der Bilder an den Auftragnehmer ab. Bilder aus anderen Medien (Internet usw.) werden aus urheberrechtlichen Gründen nicht verarbeitet. Die Beweispflicht das die Fotos aus Privatbesitz stammen obliegt bei dem AG. Nach Vertragsende werden die Fotos zurück gegeben bzw. vom Server gelöscht. Ich behalte mir vor erstellte Silhouetten zu vervielfältigen.</li>
                            <li>Alle Bilder und Texte auf dieser Seite  sind urheberrechtlich geschützt. Eine Veröffentlichung (auch auszugsweise) ist untersagt. Zuwiderhandlungen werden verfolgt</li>
                            <li>Der AG willigt ein das seine erstellte Produkte fotografiert werden und auf dieser Internetpräsenz gezeigt werden dürfen. </li>
                            
                            <h1 class="pt-5 text-3xl">Gewährleistung</h1>
                            <li>Verbrauchern steht für die angebotenen Leistungen ein gesetzliches Mängelhaftungsrecht nach den einschlägigen Vorschriften des Bürgerlichen Gesetzbuch (BGB) zu.</li>
                            
                            <h1 class="pt-5 text-3xl">Widerrufsrecht und Kundendienst</h1>
                            <li>Widerrufsbelehrung Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag der Auftragserteilung. Es gibt keinen Kundendienst.  Bei Einzel und Sonderanfertigungen gilt nach Vertragsabschluss kein Widerrufsrecht.  Hier greift der §312 Abs.1</li>
                            <li>Bei Sonderanfertigungen/ Sonderaufträgen können diese nicht mehr nach Beginn bzw. Ausführung der Arbeiten  Widerrufen werden. Die daraus entstandenen Arbeits und Materialkosten begleicht der Auftraggeber/Kunden.</li>
                        </ul> 

                         <a href="pdf/widerrufsformular.pdf" className="flex justify-center">
                            <button className="flex flex-col items-center mt-6">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                                Widerruf/Widerspruch.pdf
                            </button>
                        </a>
                    </p>

                </div>
            </div>
        </div>
    );
}

export default AGB;