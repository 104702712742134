import React from "react";
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';


function Cards(props) {
    const {ref, inView} = useInView({
      threshold: 0.3,
      triggerOnce: true
    }); // inView : bool, ref: monitor assigned
    
    const animation = useAnimation();

    useEffect( () => { //fires when inView

      if(inView) {
          animation.start({
              x: 0,
              transition: {
                  type: 'spring', 
                  duration: 0.90, 
                  bounce:0.3
              }
          });
      }
      if(!inView) {
          animation.start({x: '-100vw'})
      }
      
    });

    return (
       <div ref = {ref}>
          <motion.div
            animate={animation}
          >
           <div className={`bg-card w-80 my-10 rounded-3xl text-white overflow-hidden ${props.shadow}`}>
            <div className="aspect-w-5 aspect-h-3">
              <img className={`object-cover ${props.pos}`} src={props.src} alt="" />
            </div>
            
            <div className="flex flex-col items-center">
              <p className="mt-3 text-lg text-pink-100 font-bold drop-shadow-xl">{props.title}</p>
              <div className="w-[90%] h-[1px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"></div>
              <p className="my-5 px-4 prose text-sm font-semibold text-gray-100 text-center">{props.text}</p>

              <div className={`${props.download} flex items-center text-base font-normal justify-center pb-3`}>
                <p className="pr-2">Mietvertrag</p>
                <a href="pdf/mietvertrag.pdf" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                </a>
              </div>
            </div>
          </div>
          </motion.div>
       </div>
    );
}

export default Cards;