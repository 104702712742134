import React from 'react'
import {NavLink} from 'react-router-dom';

function Impressum() {
    return (
        <div className="w-full flex flex-col h-screen">
            <div className="flex pl-6 items-center py-3 h-16 bg-indigo-700">
                <NavLink to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 stroke-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </NavLink>
            </div>
            <div className="flex-1 bg-gray-200 flex justify-center pt-4 px-5">
                <p class="prose lg:prose-md max-w-5xl pb-8">
                    <h1 class="text-4xl underline decoration-indigo-500">Impressum</h1>
                    <h1 class="pt-5 text-xl">Carl-Schmöle-Str. 51c, 58706 Menden</h1>             
                    <h1 class="pt-5 text-xl">Telefon: (+49)2373 9813133</h1> 
                    <h1 class="pt-5 text-xl">Email: TorstenRandhahn@gmail.com</h1> 
                    <h1 class="pt-5 text-xl">USt-IdNr: DE357689376</h1> 
                    <h1 class="pt-5 text-xl">Kleingewerbe nach §19 ustg seit 01.03.2014</h1> 
                    <br></br>
                    <h1 class="text-3xl underline decoration-indigo-500">Bankverbindung</h1> 
                    <h1 class="pt-5 text-xl">DAB München</h1> 
                    <h1 class="pt-5 text-xl">IBAN: DE 88 7012 0400 7065269024</h1> 
                    <h1 class="pt-5 text-xl">BIC: DABBDEMMXXX</h1> 
                    <h1 class="pt-5 text-xl">PayPal Konto: TorstenRandhahn@gmail.com</h1> 
                </p>
            </div>
        </div>
    )
}

export default Impressum
