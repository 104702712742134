import './App.css';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './Pages/Home'
import AGB from './Pages/AGB'
import Datenschutz from './Pages/Datenschutz'
import Impressum from './Pages/Impressum'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/agb" element={<AGB/>} />
        <Route path="/datenschutz" element={<Datenschutz/>} />
        <Route path="/impressum" element={<Impressum/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;
