import React from "react";

function CardsRight(props) {
    return (
        <div className="max-w-2xl xl:max-w-full xl:w-screen mr-3 ml-3 xl:mr-0 xl:ml-0">
            <div className="flex rounded-xl xl:rounded-none overflow-hidden">
                <div className="bg-card w-full">
                    <article class="text-gray-100 text-sm prose font-semibold px-3 pb-5
                    xl:max-w-none xl:prose-2xl xl:text-lg xl:px-0 xl:pb-0 xl:pt-6 xl:pl-8 
                    xl:w-full xl:h-full xl:flex xl:flex-col xl:items-center xl:justify-center">
                        <h1 className="underline decoration-3 decoration-curve text-xl sm:text-2xl text-gray-100 mt-2">{props.title}</h1>
                        <p className="xl:text-center xl:px-20">
                            {props.text}
                        </p>                
                    </article>
                </div>   
                <div className="hidden w-56 mobile-ready:block  xl:w-full xl:aspect-w-5 xl:aspect-h-2">
                    <img class="object-cover h-full" src={props.src} alt="" />
                </div>
            </div>
        </div>
    );
}

export default CardsRight;