
import React from "react";
import {motion} from 'framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';

function CardsExtend(props) {
    const {ref, inView} = useInView({
        threshold: 0.3,
        triggerOnce: true
      }); // inView : bool, ref: monitor assigned
      
      const animation = useAnimation();
  
      useEffect( () => { //fires when inView
  
        if(inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', 
                    duration: 0.90, 
                    bounce:0.3
                }
            });
        }
        if(!inView) {
            animation.start({x: '-100vw'})
        }
        
      });
  
    return (
        <div ref = {ref}>
        <motion.div
          animate={animation}
        >
            <div className={`${props.shadow} bg-card  flex rounded-3xl overflow-hidden max-w-screen-sm`}>
                <div className="w-40 m-4 aspect-w-4 aspect-h-1">
                    <img className="object-cover object-center rounded-xl" src={props.src} alt="" />
                </div>
                <div className="flex-1 ml-5 mt-2 text-pink-100 font-bold text-xl">
                    <p>{props.title}</p>
                    <div className="w-[90%] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 h-[1px]"></div>
                        <p className="text-white text-sm font-semibold my-2 mr-5">
                            {props.description}
                        </p>

                        <div className={`${props.download} flex items-center text-base font-normal justify-end mr-6 mt-10`}>
                            <p className="pr-2">Mietverträge</p>
                            <a href="pdf/mietvertrag.pdf" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                            </a>
                        </div>
                </div>
            </div>
        </motion.div>
        </div>
    );
}

export default CardsExtend;