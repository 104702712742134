import React from "react";
import {motion} from 'framer-motion';

import ProductSection from '../components/ProductSection';
import Contact from '../components/Contact'
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import CardSection from '../components/Cards/CardSection';
import Footer from '../components/Footer';
import Video from '../archive/video/stock.mp4';

function Home() {
  return (
    <div className="scroll-smooth overflow-hidden">
      <motion.div initial={{opacity:0}} transition={{duration: 1.8}} animate={{opacity: 1}} >
          <div className="h-screen flex flex-col drop-shadow-md">
            <Navbar />
            <Hero />
            <video className="h-full blur w-full aspect-auto object-cover" autoPlay loop muted>
                <source class="flex-grow-0 max-h-full"src={Video} type='video/mp4' />
            </video>
          </div>

          <div id="galery">
            <CardSection/>
          </div>

          <div id="product" className=" bg-curve flex justify-center xl:justify-start pb-5">
            <ProductSection />
          </div>

          <div id="contact" className="bg-gray-200 relative">
            <Contact/>
            <Footer/>
          </div>
      </motion.div>
    </div>
  );
}

export default Home;