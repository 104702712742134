import React from "react";

import CardsRight from './Cards/CardsRight';

import cardImg1 from '../archive/img/fass3.jpg';
import cardImg2 from '../archive/img/fass4.jpg';
import cardImg3 from '../archive/img/fass5.jpg';
import cardImg4 from '../archive/img/fassClose.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ProductSection() {
    return (
    <>
    <div className="space-y-9 xl:space-y-0 flex flex-col">
        <div className="pb-10 drop-shadow-xl mobile-ready:hidden">
            <Carousel className="pt-10" infiniteLoop={true} autoPlay={true} showStatus={false} showThumbs={false} preventMovementUntilSwipeScrollTolerance={true}>
                <div>
                    <img src={cardImg1} alt=""/>
                </div>
                <div>
                    <img src={cardImg2} alt=""/>
                </div>
                <div>
                    <img src={cardImg3} alt=""/>
                </div>
                <div>
                    <img src={cardImg4} alt=""/>
                </div>
            </Carousel>
        </div>

        <div className="max-w-2xl xl:max-w-full xl:w-screen mr-3 ml-3 xl:mr-0 xl:ml-0">
            <div className="flex rounded-xl xl:rounded-none overflow-hidden">
                <div className="hidden w-56 mobile-ready:block xl:w-full xl:aspect-w-5 xl:aspect-h-2">
                    <img class="object-cover h-full" src={cardImg3} alt="" />
                </div>
                <div className="bg-card w-full">
                    <article class="text-gray-100 text-sm prose font-semibold px-3 pb-5
                    xl:max-w-none xl:prose-2xl xl:text-lg xl:px-0 xl:pb-0 xl:pt-6 xl:pl-8 
                    xl:w-full xl:h-full xl:flex xl:flex-col xl:items-center xl:justify-center">
                        <h1 className="underline decoration-3 decoration-curve text-xl sm:text-2xl text-gray-100 mt-2">100% Handarbeit</h1>
                        <p className="xl:text-center xl:px-20">
                        In einem aufwändigen Verfahren werden unsere gebrauchten Weinfässer abgeschmirgelt und mit einer Tür ausgestattet, 
                        sodass eine handelsübliche Propangasflasche darin Platz findet. Neben aufwändig von uns erstellten Scharnieren und Handgriffen, werden auch die 
                        Tischplatten (Durchmesser 1,00m, Eiche 2,8cm) und Unterkonstruktionen für die Armaturen von uns erstellt. Damit die Weinfassfeuer wetterfest sind, werden sie mit Bootslack versiegelt. 
                        Bitte beachten sie die
                        <a href="pdf/pflegehinweise.pdf" className="text-indigo-400 no-underline"> Pflegehinweise</a> sowie die <a href="pdf/warnhinweise.pdf" className="text-indigo-400 no-underline"> Warnhinweise</a>.
                        </p>                
                    </article>
                </div>   
            </div>
        </div>

        <CardsRight
            title="Einbaubrenner" 
            text="Unsere Fässer besitzen einen Einbaubrenner von Firestar mit 8,5KW, sowie einen Gasbrenner (40cm * 40cm * 18,5cm) in Edelstahl.
            Darin enthalten Gasschlauch, Druckminderer (nicht für Gewerbebetrieb) und Piezozündung (Batteriebetrieben). Hinzu kommt eine Abdeckplatte aus Edelstahl, 
            Glasaufsatz (44cm * 44cm * 18cm) und Lavasteine. Klassisch dazu bieten wir neben dem 40cm * 40cm Edelstahl Einbaubrenner auch eine runde Version mit einem
            Ø von 40cm an. Hierzu passend, liefern wir selbstverständlich das Sicherheitsglas in runder Form. Betriebsdauer bei einer handelsüblichen 11Kg Propangasflasche
            beläuft sicha auf 40 - 50 Stunden."
            src={cardImg2}
        />

        <div className="max-w-2xl xl:max-w-full xl:w-screen mr-3 ml-3 xl:mr-0 xl:ml-0">
            <div className="flex rounded-xl xl:rounded-none overflow-hidden">
                <div className="hidden w-56 mobile-ready:block xl:w-full xl:aspect-w-5 xl:aspect-h-2">
                    <img class="object-cover h-full" src={cardImg1} alt="" />
                </div>
                <div className="bg-card w-full">
                    <article class="text-gray-100 text-sm prose font-semibold px-3 pb-5
                    xl:max-w-none xl:prose-2xl xl:text-lg xl:px-0 xl:pb-0 xl:pt-6 xl:pl-8 
                    xl:w-full xl:h-full xl:flex xl:flex-col xl:items-center xl:justify-center">
                        <h1 className="underline decoration-3 decoration-curve text-xl sm:text-2xl text-gray-100 mt-2">Kontakt</h1>
                        <p className="xl:text-center xl:px-20">
                        Schreiben Sie uns mit dem unten angegebenen Formular, oder melden Sie sich telefonisch mit der im Impessum angegebenen Nummer.
                        </p>                
                    </article>
                </div>   
            </div>
        </div>
            
        <CardsRight
            title="Nicht nur Weinfässer!" 
            text="Das kleine Unternehmen Projekte-aus-Holz eröffnete seine Werkstatt im Jahr 2013. Neben Gartenstelen, Silhouetten und Vogelhäusern wurde dann
            im Jahr 2021 die Palette der Angebote um Weinfassfeuer vergrößert." 
            src={cardImg4}
        />
    </div>
    </>
    );
}

export default ProductSection;
