import React from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import {NavLink} from 'react-router-dom';

function Contact() {
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            name: "",
            nachname: "",
            email: "",
            straße: "",
            plz: "",
            ort: "",
            option: "",
            checkbox: false
        }
    });

    const error_stlye = "border-none ring-2 ring-red-600 focus:ring-red-600";

    const sendEmail = (e) => {
        emailjs.send('service_xjsreyi', 'template_wpar0b9', e, "user_YvmcSC488Fv5rnUVy2Oal")
        .then(response => {
            console.log("SUCCSESS", response);
            document.getElementById("form").reset();
            document.getElementById("success").setAttribute("class", "block h-6 w-6");
            document.getElementById("subButton").setAttribute("class", "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 ");
            document.getElementById("send").setAttribute("class", "hidden");
        }, error => {
            console.log("FAILED", error);
        })
        
    };

    return (
        <div className="bg-card pt-20 flex flex-col items-center">
            <div class="custom-shape-divider-top-1641137260">
                <svg className="fill-curve" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>


            <h1 className="z-50 text-5xl mobile-ready:text-6xl font-bold text-curve py-8 mb-5">Interesse?</h1>

            <form id="form" onSubmit={handleSubmit((data)=> {sendEmail(data)})} 
            className="space-y-8 flex flex-col max-w-lg w-[85%]" method="post">
                <div className="flex space-x-2">
                    <div className="flex flex-col relative w-32">
{/* NAME */}
                            <input {...register("name", {pattern: /^([^0-9]*)$/, required:true, maxLength:20} )} id="name" name="name" placeholder="name" type="text" 
                                className={`placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                                focus:border-none ${errors.name && error_stlye}`}/>
                            <label for="name" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                                text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                                peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                                peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                                    Name
                            </label>
                    </div>
                    <div className="flex flex-col relative w-full">
{/* NACHNAME */}
                            <input {...register("nachname", {pattern: /^([^0-9]*)$/, required:true,maxLength:35})} id="nachname" name="nachname" placeholder="nachname" type="text"
                            className={`w-full placeholder-transparent peer border text-white bg-transparent rounded-lg 
                            shadow-sm focus:outline-none focus:ring-2 focus:ring-curve focus:border-none ${errors.nachname && error_stlye}`}/>
                        
                            <label for="nachname" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                                text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                                peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                                peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                                    Nachname
                            </label>
                        </div>
                </div>

                <div className="flex flex-col relative">
{/* EMAIL */}
                    <input {...register("email", {maxLength:50, required:true})} id="email" name="email" placeholder="email" type="email" 
                    className={`placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                    focus:border-none ${errors.email && error_stlye}`}/>
                
                    <label for="email" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                        text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                        peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                        peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                            E-Mail-Adresse
                    </label>
                </div>

                <div className="flex flex-col relative">
{/* STRAßE */}
                    <input {...register("straße", {maxLength:40, required:true})} id="straße" name="straße" placeholder="straße" type="text" 
                    className={`placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                    focus:border-none ${errors.straße && error_stlye}`}/>
                    
                    <label for="straße" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                        text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                        peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                        peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                            Straße
                    </label>
                </div>


                <div className="flex space-x-2">
                    <div className="flex flex-col relative w-32">
                        <div className="flex flex-col relative">
{/* PLZ */}
                            <input {...register("plz", {pattern: /[0-9]{5}/, required:true})} id="plz" name="plz" placeholder="plz" type="number" 
                            className={`placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                            focus:border-none ${errors.plz && error_stlye}`}/>
                        
                            <label for="plz" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                                text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                                peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                                peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                                    Postleitzahl
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-col relative w-full">
{/* ORT */}
                            <input {...register("ort", {pattern: /^([^0-9]*)$/, required:true})} id="ort" name="ort" placeholder="ort" type="text" 
                            className={`w-full placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                            focus:border-none ${errors.ort && error_stlye}`}/>
                            
                            <label for="ort" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                                text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                                peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                                peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                                    Ort
                            </label>
                    </div>
                </div>

{/* SELECT */}
                <select {...register("option", {required:true})} 
                className={`rounded-lg bg-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-curve focus:border-none text-white font-normal ${errors.option && error_stlye}`}>
                    <option value="" className="bg-card">Bitte auswählen</option>
                    <option value="Kaufen" className="bg-card">Kaufen</option>
                    <option value="Mieten" className="bg-card">Mieten</option>
                    <option value="Informieren" className="bg-card">Informieren</option>
                </select>

                <div className="flex flex-col relative">
{/* MSG */}
                            <TextareaAutosize minRows={3} maxRows={6}  {...register("msg", {maxLength: 375, required:true})} id="msg" name="msg" placeholder="msg" 
                            className={`h-28 overflow-auto resize-none placeholder-transparent peer border text-white bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-curve 
                            focus:border-none ${errors.msg && error_stlye}`}/>
                        
                            <label for="msg" className="transition-all absolute left-0 -top-6 text-white font-semibold 
                                text-sm peer-placeholder-shown:text-gray-300 peer-placeholder-shown:font-medium 
                                peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:-top-6
                                peer-focus:font-semibold peer-focus:text-sm peer-focus:text-white peer-focus:left-0">
                                    Nachricht (max. 375 Zeichen)
                            </label>
                        </div>

                <div class="flex items-center">
{/* CHECKBOX */}
                    <input {...register("checkbox", {required:true})} id="checkbox" name="checkbox" type="checkbox" 
                    class={`border-gray-300 text-indigo-600 focus:ring-indigo-500 rounded ${errors.checkbox && error_stlye}`} />
                    
                    <label for="checkbox" class="ml-2 block text-sm text-white font-semibold"
                        >Ich habe die
                        <NavLink to='/agb'><span class="text-indigo-400 hover:text-indigo-500"> AGB's </span></NavLink> &
                        <NavLink to='/datenschutz'> <span class="text-indigo-400 hover:text-indigo-500"> Datenschutz </span></NavLink>
                        gelesen.
                    </label>
                </div>

                <div>
                    <button id="subButton" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <p id="send">Senden</p>
                        <svg id="success" xmlns="http://www.w3.org/2000/svg" class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Contact;