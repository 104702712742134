import React from "react";
import {Link} from 'react-scroll';

function Hero() {
    return (
        <div>
            <div className="absolute text-white w-full h-full flex flex-col items-center justify-center select-none z-40">
                <p className="text-white font-semibold text-3xl md:text-6xl" >Willkommen bei</p>
                <br></br>
                <p className="text-white font-bold text-5xl md:text-8xl underline decoration-red-500/40" >Weinfassfeuer</p>
        </div>
            <div className="absolute left-0 right-0 flex justify-center bottom-0 z-40 mb-10">
                <Link to="galery">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        class="animate-bounce hover:cursor-pointer h-14 w-14 stroke-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z" />
                    </svg>
                </Link>
            </div>
        </div>
    );
}

export default Hero;