import React from "react";

import Cards from './Cards';
import CardsExtend from "./Cards_expanded";
import cardImg1 from '../../archive/img/spark.jpg';
import cardImg2 from '../../archive/img/fass2.png';
import cardImg3 from '../../archive/img/fasss.jpg';

function CardSection(props) {
    return (
        <div>
            <div className="xl:hidden">
                <div className="drop-shadow-none flex items-center justify-center pb-20 pt-10">
                    <Cards 
                    title="Unsere Weinfässer"
                    shadow="shadow-lg shadow-gray-800/50" 
                    download="hidden"
                    src={cardImg1} 
                    text="Unsere Fässer stammen aus verschiedenen Weinanbaugebieten Frankreichs. Hierbei handelt es sich
                    um alte, benutzte Weinfässer aus Eiche. Diese werden dann in einem aufwändigen Prozess 
                    zu unseren Stehbiertischen umgebaut."/>

                    <div class="custom-shape-divider-bottom-1640788416 -z-10">
                        <svg class="fill-curve h-24 w-full" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>

                <div className="bg-curve drop-shadow-none flex items-center justify-center pb-20 pt-5">
                    <Cards 
                    title="Verkauf" 
                    pos="object-top" 
                    src={cardImg2} 
                    download="hidden"
                    text="Unsere Stehbiertische stehen auf Anfrage zum Verkauf. Bitte beachten Sie hierbei allerdings,
                    dass wir ein kleines Unternehmen sind und nicht immer Fässer auf Lager haben. Für weitere Informationen
                    schreiben Sie uns doch gerne eine Nachricht!"/>
                    
                    <div class="custom-shape-divider-bottom-1640799742 -z-10">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>

                <div className="drop-shadow-none flex items-center justify-center pb-20 pt-5">
                    <Cards 
                    shadow="shadow-lg shadow-gray-800/50" 
                    title="Vermietung" 
                    pos="object-top" 
                    src={cardImg3} 
                    text="Zudem bieten wir unsere Fässer zur Vermietung an. Unser Frühjahresangebot 2022: Ein Wochende, Lieferung bis 30km inkl. Gas und Abtransport 
                    für 150€"/>
                    
                    <div class="custom-shape-divider-bottom-1640799742 -z-10">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                        </svg>
                    </div>
                    
                    <div class="custom-shape-divider-bottom-1640960853 -z-10">
                        <svg class="fill-curve" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>
            </div> 

            <div className="hidden xl:block">
                <div className="drop-shadow-none pt-24  pb-32">
                    <div className="space-y-20">
                        <div className="ml-5">
                            <CardsExtend 
                                src={cardImg1} 
                                download="hidden"
                                title="Unsere Weinfässer" 
                                shadow="shadow-2xl shadow-gray-800/50"
                                description="Unsere Fässer stammen aus verschiedenen Weinanbaugebieten Frankreichs. Hierbei handelt es sich
                                um alte, benutzte Weinfässer aus Eiche. Diese werden dann in einem aufwändigen Prozess 
                                zu unseren Stehbiertischen umgebaut."
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <CardsExtend 
                                src={cardImg2}
                                download="hidden" 
                                title="Verkauf" 
                                shadow="shadow-2xl shadow-gray-800/50"
                                description="Unsere Stehbiertische stehen auf Anfrage zum Verkauf. Bitte beachten Sie hierbei allerdings,
                                dass wir ein kleines Unternehmen sind und nicht immer Fässer auf lager haben. Für weitere Informationen
                                schreiben Sie uns doch gerne eine Nachricht!"
                            />
                        </div>
                        <div className="flex items-center justify-end mr-5">
                            <CardsExtend 
                                src={cardImg3} 
                                title="Vermietung" 
                                shadow="shadow-2xl shadow-gray-800/50"
                                description="Zudem bieten wir unsere Fässer zur Vermietung an. Schauen Sie für weitere Informationen in unsere Mietverträge."
                            />
                        </div>
                    </div>
                    <div class="custom-shape-divider-bottom-1640788416 -z-10">
                        <svg class="fill-curve h-80 w-full" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>
            </div>               
        </div>
    );
}

export default CardSection;